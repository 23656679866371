import gql from 'graphql-tag';
import { Client as GQLClient, OperationResult } from 'urql';
import {
  GQBackgroundJobState,
  GQGetJobStatusQuery,
  GQGetJobStatusQueryVariables,
  GQListJobsStatusQuery,
  GQListJobsStatusQueryVariables,
  GQGetJobResultsQuery,
  GQGetJobResultsQueryVariables,
} from '@watershed/shared-universal/generated/graphql';
import {
  GetJobStatusDocument,
  GetJobResultsDocument,
  ListJobsStatusDocument,
} from '../generated/urql';
import {
  parseBackgroundJobResult,
  BackgroundJobError,
  BackgroundJobErrorReason,
} from '@watershed/shared-universal/utils/backgroundJobUtils';
import { WsError } from './errorUtils';

export const NETWORK_ERROR_RETRY_LIMIT = 3;
export const NETWORK_ERROR_RETRY_DELAY = 3000; // 3s
export const POLL_INTERVAL = 500; // 0.5s
export const MAX_POLL_ATTEMPTS = 3600; // 30 minutes

gql`
  fragment SerializableErrorFields on SerializableError {
    message
    code
    stackTrace
    errorType
    details
  }

  # eslint-disable-next-line @watershed/gql-operations-must-assign-owners
  query GetJobResults($id: ID!) {
    job: backgroundJob(id: $id) {
      id
      kind
      state
      args
      result
      error {
        ...SerializableErrorFields
      }
    }
  }

  # eslint-disable-next-line @watershed/gql-operations-must-assign-owners
  query GetJobStatus($id: ID!) {
    job: backgroundJobStatus(id: $id) {
      id
      orgId
      state
      error {
        ...SerializableErrorFields
      }
    }
  }

  # eslint-disable-next-line @watershed/gql-operations-must-assign-owners
  query ListJobsStatus($ids: [ID!]!) {
    backgroundJobStatuses(ids: $ids) {
      id
      orgId
      state
      error {
        ...SerializableErrorFields
      }
    }
  }

  # eslint-disable-next-line @watershed/gql-operations-must-assign-owners
  mutation CancelWorkflow($input: CancelWorkflowInput!) {
    cancelWorkflow(input: $input) {
      success
    }
  }
`;

export class ExceededMaxAttemptsError extends Error {
  graphQLErrors = [];
  constructor() {
    super('Exceeded polling attempts limit. Job is still running.');

    // Set the prototype explicitly.
    // Unfortunately necessary so `err instanceof ExceededMaxAttemptsError` works.
    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, ExceededMaxAttemptsError.prototype);
  }
}

export async function getJobResults(gqlClient: GQLClient, id: string) {
  return gqlClient
    .query<GQGetJobResultsQuery, GQGetJobResultsQueryVariables>(
      GetJobResultsDocument,
      { id },
      { requestPolicy: 'network-only' }
    )
    .toPromise();
}

export async function getJobStatus(gqlClient: GQLClient, id: string) {
  return gqlClient
    .query<GQGetJobStatusQuery, GQGetJobStatusQueryVariables>(
      GetJobStatusDocument,
      { id },
      { requestPolicy: 'network-only' }
    )
    .toPromise();
}

export async function listJobsResults(
  gqlClient: GQLClient,
  ids: Array<string>
) {
  return gqlClient
    .query<GQListJobsStatusQuery, GQListJobsStatusQueryVariables>(
      ListJobsStatusDocument,
      { ids },
      { requestPolicy: 'network-only' }
    )
    .toPromise();
}

/**
 * Fetches background job results.
 *
 * If you don't need the results please use pollForJobStatus especially
 * in app-dashboard. Given that temporal jobs are not org-scoped and we do not
 * have permissions on the generic backgroundJob query we
 * should limit exposure of background job results.
 */
export async function pollForJobResults(
  gqlClient: GQLClient,
  id: string,
  getShouldCancelPolling: () => boolean = () => false
): Promise<OperationResult<GQGetJobResultsQuery>> {
  let tries = 0;
  let networkErrorCount = 0;
  while (true) {
    const res = await getJobResults(gqlClient, id);

    const cancelPolling = getShouldCancelPolling();

    if (cancelPolling) {
      return res;
    }

    if (res.error) {
      // For polling job results we add another layer of retries, on top of the
      // retry exchange applied to all urql requests. For background jobs,
      // better to sit around another 20 seconds retrying than to error out
      // early.
      if (
        res.error.networkError &&
        networkErrorCount < NETWORK_ERROR_RETRY_LIMIT
      ) {
        networkErrorCount += 1;
        await new Promise((resolve) =>
          setTimeout(resolve, NETWORK_ERROR_RETRY_DELAY)
        );
        continue;
      }

      return res;
    }

    const jobState = res.data?.job?.state;
    if (
      jobState === GQBackgroundJobState.Queued ||
      jobState === GQBackgroundJobState.Processing
    ) {
      if (tries++ > MAX_POLL_ATTEMPTS) {
        return {
          ...res,
          data: undefined,
          error: new ExceededMaxAttemptsError(),
        };
      }

      await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
      // continue to next poll iteration
    } else {
      return res;
    }
  }
}

type ParseBackgroundJobUrqlResultOutput =
  | {
      result: Record<string, any>;
      error?: null;
    }
  | {
      result?: null;
      error: BackgroundJobError | WsError | null;
    };

/**
 * Looks for errors in all the nooks and crannies of a background job urql
 * response, and exposes the errors that it finds.
 */
export function parseBackgroundJobUrqlResult(
  r: OperationResult<GQGetJobResultsQuery, any>
): ParseBackgroundJobUrqlResultOutput {
  const output: ParseBackgroundJobUrqlResultOutput = {
    result: null,
    error: null,
  };

  if (r.error) {
    output.error = WsError.fromUrqlCombinedError(r.error);
    return output;
  }

  if (!r.data?.job) {
    output.error = new BackgroundJobError(
      'Background job GraphQL result has no error and no data',
      BackgroundJobErrorReason.UNKNOWN,
      { graphQlResult: r }
    );
    return output;
  }

  return parseBackgroundJobResult(r.data?.job);
}

/**
 * Fetches background job status.
 */
export async function pollForJobStatus(
  gqlClient: GQLClient,
  id: string,
  getShouldCancelPolling: () => boolean = () => false
): Promise<OperationResult<GQGetJobStatusQuery>> {
  let tries = 0;
  let networkErrorCount = 0;
  while (true) {
    const res = await getJobStatus(gqlClient, id);

    const cancelPolling = getShouldCancelPolling();

    if (cancelPolling) {
      return res;
    }

    if (res.error) {
      // For polling job results we add another layer of retries, on top of the
      // retry exchange applied to all urql requests. For background jobs,
      // better to sit around another 20 seconds retrying than to error out
      // early.
      if (
        res.error.networkError &&
        networkErrorCount < NETWORK_ERROR_RETRY_LIMIT
      ) {
        networkErrorCount += 1;
        await new Promise((resolve) =>
          setTimeout(resolve, NETWORK_ERROR_RETRY_DELAY)
        );
        continue;
      }

      return res;
    }

    const jobState = res.data?.job?.state;
    if (
      jobState === GQBackgroundJobState.Queued ||
      jobState === GQBackgroundJobState.Processing
    ) {
      if (tries++ > MAX_POLL_ATTEMPTS) {
        return {
          ...res,
          data: undefined,
          error: new ExceededMaxAttemptsError(),
        };
      }

      await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
      // continue to next poll iteration
    } else {
      return res;
    }
  }
}
